import { useState } from 'react';
import { useRouter } from 'next/router';
import Modal from 'components/Modal';
import { Organization } from 'interfaces';
import { createNewGame, HTTPError, track } from 'utils/api';
import { playersIcon, clockIcon } from 'utils/icons';

type Props = {
  organization?: Organization;
};

const GameListing = ({ organization }: Props) => {
  const [error, setError] = useState({ hidden: true, heading: '', body: '' });
  const [showQuotaExceededError, setShowQuotaExceededError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  async function handleStartGame(gameID: string) {
    setIsLoading(true);

    try {
      const res = await createNewGame(
        gameID,
        organization ? organization.id : undefined,
      );

      setIsLoading(false);
      router.push(`/${res.data.game_id}/rooms/${res.data.code}`);
    } catch (e) {
      setIsLoading(false);

      if (e instanceof HTTPError && e.response.status === 422) {
        const error = await e.response.json();
        if (error.errors.organization_id) {
          setShowQuotaExceededError(true);
          track('quota_exceeded_popup', { activity: gameID }, organization?.id);
        }
      } else {
        setError({
          hidden: false,
          heading: 'Error creating game room!',
          body: 'There was a problem creating the game room. Please try again',
        });
      }
    }
  }

  return (
    <>
      <Modal
        {...error}
        showConfirmButton={true}
        confirmLabel="Ok"
        modalType={'error'}
        onConfirm={() => setError({ hidden: true, heading: '', body: '' })}
      />

      <Modal
        hidden={!showQuotaExceededError}
        showConfirmButton={true}
        confirmLabel="Upgrade Plan"
        modalType={'info'}
        onConfirm={() => router.push(`/lounge/${organization?.slug}/billing`)}
        body="Your team's free trial of Lounge is over 😞 But this doesn't have to mean goodbye, upgrade to one of our paid plans and keep the party going!"
        heading="Time to upgrade!"
      />

      {/* Werewolf */}
      <div className="mt-4 p-4 rounded-md bg-yellow-100 border border-yellow-300 text-yellow-900">
        <h2 className="text-2xl font-bold leading-relaxed">Werewolf</h2>

        <div className="mt-2 flex flex-wrap font-semibold text-yellow-800">
          <p className="p-1 mb-2 mr-2 bg-yellow-200 border border-yellow-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{playersIcon}</span>
            <span>4-16 Players</span>
          </p>

          <p className="p-1 mb-2 mr-2 bg-yellow-200 border border-yellow-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{clockIcon}</span>
            <span>20-60 mins per game</span>
          </p>

          <p className="p-1 mb-2 bg-yellow-200 border border-yellow-300 rounded">
            Free for all
          </p>
        </div>

        <p className="mt-2 font-semibold text-lg">
          What we love: Hit among tech community, suspenseful, encourages
          communication.
        </p>

        <div className="mt-2 flex items-center justify-between">
          <p>
            This classic party game has taken over the tech community by storm
            and rightly so! Some people make lifelong friendships over this
            amazing game from the secret identity genre.
          </p>

          <img
            src="/werewolf.png"
            alt="Werewolf thumbnail"
            className="w-1/3 ml-2"
          />
        </div>

        <div className="flex justify-between">
          <button
            className="mt-4 btn btn-yellow"
            onClick={() => handleStartGame('werewolf')}
            disabled={isLoading}
          >
            Play Now
          </button>

          <a
            className="mt-4 btn btn-yellow-secondary"
            href="https://getlounge.app/werewolf-how-to-play"
            target="_blank"
          >
            How to Play
          </a>
        </div>
      </div>

      {/* Codenames */}
      <div className="mt-4 p-4 rounded-md bg-orange-100 border border-orange-300 text-orange-900">
        <h2 className="text-2xl font-bold leading-relaxed">Codenames</h2>

        <div className="mt-2 flex flex-wrap font-semibold text-orange-800">
          <p className="p-1 mb-2 mr-2 bg-orange-200 border border-orange-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{playersIcon}</span>
            <span>4-8 Players</span>
          </p>

          <p className="p-1 mb-2 mr-2 bg-orange-200 border border-orange-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{clockIcon}</span>
            <span>20-40 mins per game</span>
          </p>

          <p className="p-1 mb-2 bg-orange-200 border border-orange-300 rounded">
            Team Game
          </p>
        </div>

        <p className="mt-2 font-semibold text-lg">
          What we love: Award winning, good for chit-chat, deduction.
        </p>

        <div className="mt-2 flex items-center justify-between">
          <p>
            Winner of Board Game Of The Year 2016, Golden Geek Award 2017 &
            more. This word based game, is like Pictionary but with words. If
            you enjoy clues and guessing, you'll love this game!
          </p>

          <img
            src="/codenames.png"
            alt="Codenames thumbnail"
            className="w-1/3 ml-2"
          />
        </div>

        <div className="flex justify-between">
          <button
            className="mt-4 btn btn-orange"
            onClick={() => handleStartGame('codenames')}
            disabled={isLoading}
          >
            Play Now
          </button>

          <a
            className="mt-4 btn btn-orange-secondary"
            href="/how-to-play-codenames.pdf"
            target="_blank"
          >
            How to Play
          </a>
        </div>
      </div>

      {/* Pictionary */}
      <div className="mt-4 p-4 rounded-md bg-purple-100 border border-purple-300 text-purple-900">
        <h2 className="text-2xl font-bold leading-relaxed">Pictionary</h2>

        <div className="mt-2 flex flex-wrap font-semibold text-purple-800">
          <p className="p-1 mb-2 mr-2 bg-purple-200 border border-purple-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{playersIcon}</span>
            <span>3-8 Players</span>
          </p>

          <p className="p-1 mb-2 mr-2 bg-purple-200 border border-purple-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{clockIcon}</span>
            <span>10-30 mins per game</span>
          </p>

          <p className="p-1 mb-2 bg-purple-200 border border-purple-300 rounded">
            Free For All
          </p>
        </div>

        <p className="mt-2 font-semibold text-lg">
          What we love: Simplicity, funny moments, creative.
        </p>

        <div className="mt-2 flex items-center justify-between">
          <p>
            Ever since it was introduced in 1985, This has been a classic party
            game fit for people of all types! One player draws while the rest
            try to guess what's being drawn. Be amazed by your team's drawing
            skills or lack thereof.
          </p>

          <img
            src="/pictionary.png"
            alt="Pictionary thumbnail"
            className="w-1/3 ml-2"
          />
        </div>

        <div className="flex justify-between">
          <button
            className="mt-4 btn btn-purple"
            onClick={() => handleStartGame('pictionary')}
            disabled={isLoading}
          >
            Play Now
          </button>

          <a
            className="mt-4 btn btn-purple-secondary"
            href="https://getlounge.app/pictionary"
            target="_blank"
          >
            How to Play
          </a>
        </div>
      </div>

      {/* Alien Invasion */}
      <div className="mt-4 p-4 rounded-md bg-green-100 border border-green-300 text-green-900">
        <h2 className="text-2xl font-bold leading-relaxed">Alien Invasion</h2>

        <div className="mt-2 flex flex-wrap font-semibold text-green-800">
          <p className="p-1 mb-2 mr-2 bg-green-200 border border-green-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{playersIcon}</span>
            <span>5-10 Players</span>
          </p>

          <p className="p-1 mb-2 mr-2 bg-green-200 border border-green-300 rounded flex items-center">
            <span className="h-5 w-5 mr-1">{clockIcon}</span>
            <span>10-30 mins per game</span>
          </p>

          <p className="p-1 mb-2 bg-green-200 border border-green-300 rounded">
            Team Game
          </p>
        </div>

        <p className="mt-2 font-semibold text-lg">
          What we love: Secret identity genre, promotes collaboration.
        </p>

        <div className="mt-2 flex items-center justify-between">
          <p>
            Aliens disguised as Humans have infiltrated Earth! Elon-Tusk, a
            prominent scientist is earth's only hope! Enjoy concealing your true
            identity while deducing that of your colleagues with this witty
            secret identity game.
          </p>

          <img
            src="/alien_invasion.png"
            alt="Alien Invasion thumbnail"
            className="w-1/3 ml-2"
          />
        </div>

        <div className="flex justify-between">
          <button
            className="mt-4 btn btn-green"
            onClick={() => handleStartGame('alien_invasion')}
            disabled={isLoading}
          >
            Play Now
          </button>

          <a
            className="mt-4 btn btn-green-secondary"
            href="https://getlounge.app/alien-invasion-how-to-play"
            target="_blank"
          >
            How to Play
          </a>
        </div>
      </div>
    </>
  );
};

export default GameListing;
