import getConfig from 'next/config';
import { CustomError } from 'ts-custom-error';

const { publicRuntimeConfig } = getConfig();
const { API_SERVER_URL } = publicRuntimeConfig;

export class HTTPError extends CustomError {
  public constructor(public response: Response) {
    super(response.statusText);
    Object.defineProperty(this, 'name', { value: 'HTTPError' });
  }
}

export const API = {
  post: (url: string, body?: any) => {
    return fetch(`${API_SERVER_URL}${url}`, {
      method: 'POST',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      if (r.ok) {
        return r.json();
      } else {
        throw new HTTPError(r);
      }
    });
  },

  get: (url: string) => {
    return fetch(`${API_SERVER_URL}${url}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((r) => {
      if (r.ok) {
        return r.json();
      } else {
        throw new HTTPError(r);
      }
    });
  },
};

export const createNewGame = (gameID: string, organizationID?: number) => {
  if (organizationID) {
    return API.post(`/api/${gameID}`, { organization_id: organizationID });
  } else {
    return API.post(`/api/${gameID}`);
  }
};

export const track = (action: string, data: any, organizationID?: number) => {
  const params = organizationID
    ? {
        organization_id: organizationID,
        action: action,
        event_data: data,
      }
    : {
        action: action,
        event_data: data,
      };
  return API.post('/api/track', params);
};

export const getBillingPortalURL = (slug: string) => {
  return API.get(`/api/organizations/${slug}/billing`);
};

export default API;
